import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Send } from 'lucide-react';
import { motion } from 'framer-motion';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../config/firebase';

const Feedback = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    country: '',
    category: 'general',
    appExperience: '',
    usability: '',
    performance: '',
    userInterface: '',
    satisfaction: '4',
    improvements: '',
    newFeatures: '',
    technicalIssues: '',
    accessibility: '',
    deviceUsed: '',
    browserUsed: '',
    recommendationLikelihood: '8',
    additionalComments: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const categories = [
    { value: 'general', label: 'General Feedback' },
    { value: 'technical', label: 'Technical Issue' },
    { value: 'feature', label: 'Feature Request' },
    { value: 'usability', label: 'Usability Feedback' },
    { value: 'performance', label: 'Performance Issue' },
    { value: 'security', label: 'Security Concern' },
    { value: 'other', label: 'Other' }
  ];

  const countries = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda",
    "Argentina", "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain",
    "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bhutan", "Bolivia",
    "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso",
    "Burundi", "Cabo Verde", "Cambodia", "Cameroon", "Canada", "Central African Republic",
    "Chad", "Chile", "China", "Colombia", "Comoros", "Congo", "Costa Rica", "Croatia", 
    "Cuba", "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic",
    "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini",
    "Ethiopia", "Fiji", "Finland", "France", "Gabon", "Gambia", "Georgia", "Germany", "Ghana",
    "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti", "Honduras",
    "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel", "Italy",
    "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Korea (North)",
    "Korea (South)", "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia",
    "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Madagascar", "Malawi", "Malaysia",
    "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania", "Mauritius", "Mexico",
    "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique",
    "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger",
    "Nigeria", "North Macedonia", "Norway", "Oman", "Pakistan", "Palau", "Palestine", "Panama",
    "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Qatar", 
    "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines",
    "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia",
    "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands",
    "Somalia", "South Africa", "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", 
    "Sweden", "Switzerland", "Syria", "Tajikistan", "Tanzania", "Thailand", "Timor-Leste",
    "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Tuvalu",
    "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States", "Uruguay",
    "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam", "Yemen", "Zambia", "Zimbabwe"
  ];

  const satisfactionLevels = [
    { value: '5', label: 'Extremely Satisfied' },
    { value: '4', label: 'Very Satisfied' },
    { value: '3', label: 'Satisfied' },
    { value: '2', label: 'Somewhat Satisfied' },
    { value: '1', label: 'Not Satisfied' }
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage('');

    try {
      const feedbackData = {
        ...formData,
        createdAt: new Date(),
        status: 'new',
        platform: {
          userAgent: navigator.userAgent,
          language: navigator.language,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          screenResolution: `${window.screen.width}x${window.screen.height}`
        }
      };

      const feedbackRef = collection(db, 'feedback');
      const docRef = await addDoc(feedbackRef, feedbackData);
      console.log('Feedback submitted successfully with ID:', docRef.id);
      
      setSubmitStatus('success');
      
      setTimeout(() => {
        navigate('/');
      }, 2000);

    } catch (error) {
      console.error('Error submitting feedback:', error);
      setErrorMessage('Failed to submit feedback. Please try again.');
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const isFormValid = () => {
    return (
      formData.name.trim() !== '' &&
      formData.email.trim() !== '' &&
      formData.category !== '' &&
      formData.appExperience.trim() !== ''
    );
  };

  return (
    <div className="min-h-screen bg-surface pt-20 pb-20">
      <div className="container mx-auto px-4 relative">
        <div className="absolute inset-0">
          <div className="absolute inset-0 cyber-dots opacity-10" />
          <div className="absolute top-0 w-full h-px bg-gradient-to-r from-transparent via-primary to-transparent" />
        </div>

        <div className="max-w-3xl mx-auto relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="card-cyber p-8"
          >
            <h1 className="text-3xl font-bold text-glow mb-2">Application Feedback Form</h1>
            <p className="text-text-secondary mb-8">
              Your feedback is invaluable in helping us enhance MakeFace. Please share your detailed experience and suggestions to help us serve you better.
            </p>

            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Basic Information Section */}
              <div className="space-y-4">
                <h2 className="text-xl font-semibold text-glow">Personal Information</h2>
                <div className="grid md:grid-cols-2 gap-4">
                  <div>
                    <label htmlFor="name" className="block text-glow mb-2">Full Name<span className="text-red-500">*</span></label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      className="w-full bg-surface-light p-3 rounded-lg border border-primary/20 focus:border-primary focus:ring-1 focus:ring-primary transition-all text-text-primary"
                      required
                      minLength={2}
                    />
                  </div>

                  <div>
                    <label htmlFor="email" className="block text-glow mb-2">Email Address<span className="text-red-500">*</span></label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      className="w-full bg-surface-light p-3 rounded-lg border border-primary/20 focus:border-primary focus:ring-1 focus:ring-primary transition-all text-text-primary"
                      required
                    />
                  </div>
                </div>

                <div className="grid md:grid-cols-2 gap-4">
                  {/* Country Dropdown */}
                  <div>
                    <label htmlFor="country" className="block text-glow mb-2">Country/Region</label>
                    <div className="relative">
                      <select
                        id="country"
                        name="country"
                        value={formData.country}
                        onChange={handleInputChange}
                        className="w-full bg-surface-light p-3 rounded-lg border border-primary/20 focus:border-primary focus:ring-1 focus:ring-primary transition-all text-text-primary appearance-none"
                        style={{ 
                          WebkitAppearance: "none",
                          MozAppearance: "none",
                          direction: "ltr"
                        }}
                      >
                        <option value="">Select your country</option>
                        {countries.map((country, index) => (
                          <option key={index} value={country} className="text-text-primary bg-surface-light">
                            {country}
                          </option>
                        ))}
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-3 text-text-secondary">
                        <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                        </svg>
                      </div>
                    </div>
                  </div>

                  {/* Category Dropdown */}
                  <div>
                    <label htmlFor="category" className="block text-glow mb-2">Feedback Category<span className="text-red-500">*</span></label>
                    <div className="relative">
                      <select
                        id="category"
                        name="category"
                        value={formData.category}
                        onChange={handleInputChange}
                        className="w-full bg-surface-light p-3 rounded-lg border border-primary/20 focus:border-primary focus:ring-1 focus:ring-primary transition-all text-text-primary appearance-none"
                        style={{ 
                          WebkitAppearance: "none",
                          MozAppearance: "none",
                          direction: "ltr"
                        }}
                        required
                      >
                        {categories.map(category => (
                          <option key={category.value} value={category.value} className="text-text-primary bg-surface-light">
                            {category.label}
                          </option>
                        ))}
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-3 text-text-secondary">
                        <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Application Experience Section */}
              <div className="space-y-4">
                <h2 className="text-xl font-semibold text-glow pt-4">Application Experience</h2>
                
                <div>
                  <label htmlFor="appExperience" className="block text-glow mb-2">
                    Please describe your overall experience with our application<span className="text-red-500">*</span>
                  </label>
                  <textarea
                    id="appExperience"
                    name="appExperience"
                    value={formData.appExperience}
                    onChange={handleInputChange}
                    rows="4"
                    className="w-full bg-surface-light p-3 rounded-lg border border-primary/20 focus:border-primary focus:ring-1 focus:ring-primary transition-all text-text-primary"
                    required
                  />
                </div>

                <div>
                  <label htmlFor="usability" className="block text-glow mb-2">
                    How would you rate the application's ease of use?
                  </label>
                  <textarea
                    id="usability"
                    name="usability"
                    value={formData.usability}
                    onChange={handleInputChange}
                    rows="3"
                    className="w-full bg-surface-light p-3 rounded-lg border border-primary/20 focus:border-primary focus:ring-1 focus:ring-primary transition-all text-text-primary"
                  />
                </div>

                <div>
                  <label htmlFor="performance" className="block text-glow mb-2">
                    How was the application's performance and speed?
                  </label>
                  <textarea
                    id="performance"
                    name="performance"
                    value={formData.performance}
                    onChange={handleInputChange}
                    rows="3"
                    className="w-full bg-surface-light p-3 rounded-lg border border-primary/20 focus:border-primary focus:ring-1 focus:ring-primary transition-all text-text-primary"
                  />
                </div>
              </div>

              {/* Technical Details Section */}
              <div className="space-y-4">
                <h2 className="text-xl font-semibold text-glow pt-4">Technical Details</h2>
                
                <div className="grid md:grid-cols-2 gap-4">
                  <div>
                    <label htmlFor="deviceUsed" className="block text-glow mb-2">Device Used</label>
                    <input
                      type="text"
                      id="deviceUsed"
                      name="deviceUsed"
                      value={formData.deviceUsed}
                      onChange={handleInputChange}
                      placeholder="e.g., iPhone 13, Desktop PC"
                      className="w-full bg-surface-light p-3 rounded-lg border border-primary/20 focus:border-primary focus:ring-1 focus:ring-primary transition-all text-text-primary"
                    />
                  </div>

                  <div>
                    <label htmlFor="browserUsed" className="block text-glow mb-2">Browser Used</label>
                    <input
                      type="text"
                      id="browserUsed"
                      name="browserUsed"
                      value={formData.browserUsed}
                      onChange={handleInputChange}
                      placeholder="e.g., Chrome, Safari"
                      className="w-full bg-surface-light p-3 rounded-lg border border-primary/20 focus:border-primary focus:ring-1 focus:ring-primary transition-all text-text-primary"
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="technicalIssues" className="block text-glow mb-2">
                    Did you experience any technical issues?
                  </label>
                  <textarea
                    id="technicalIssues"
                    name="technicalIssues"
                    value={formData.technicalIssues}
                    onChange={handleInputChange}
                    rows="3"
                    className="w-full bg-surface-light p-3 rounded-lg border border-primary/20 focus:border-primary focus:ring-1 focus:ring-primary transition-all text-text-primary"
                    placeholder="Please describe any technical issues you encountered"
                  />
                </div>
              </div>

              {/* Satisfaction and Recommendations Section */}
              <div className="space-y-4">
                <h2 className="text-xl font-semibold text-glow pt-4">Satisfaction Level</h2>
                
                {/* Updated Satisfaction Dropdown */}
                <div>
                  <label htmlFor="satisfaction" className="block text-glow mb-2">Overall Satisfaction</label>
                  <div className="relative">
                    <select
                      id="satisfaction"
                      name="satisfaction"
                      value={formData.satisfaction}
                      onChange={handleInputChange}
                      className="w-full bg-surface-light p-3 rounded-lg border border-primary/20 focus:border-primary focus:ring-1 focus:ring-primary transition-all text-text-primary appearance-none"
                      style={{ 
                        WebkitAppearance: "none",
                        MozAppearance: "none",
                        direction: "ltr"
                      }}
                    >
                      {satisfactionLevels.map(level => (
                        <option key={level.value} value={level.value} className="text-text-primary bg-surface-light">
                          {level.label}
                        </option>
                      ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-3 text-text-secondary">
                      <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                      </svg>
                    </div>
                  </div>
                </div>

                <div>
                  <label htmlFor="recommendationLikelihood" className="block text-glow mb-2">
                    How likely are you to recommend our application? 
                  </label>
                  <input
                    type="range"
                    id="recommendationLikelihood"
                    name="recommendationLikelihood"
                    min="1"
                    max="10"
                    value={formData.recommendationLikelihood}
                    onChange={handleInputChange}
                    className="w-full h-2 bg-surface-light rounded-lg appearance-none cursor-pointer"
                  />
                  <div className="flex justify-between px-1 mt-1">
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((number) => (
                      <span key={number} className="text-sm text-text-secondary">
                        {number}
                      </span>
                    ))}
                  </div>
                  <div className="flex justify-between text-sm text-text-secondary mt-1">
                    <span>Not likely</span>
                    <span>Very likely</span>
                  </div>
                </div>
              </div>

              {/* Additional Comments Section */}
              <div className="space-y-4">
                <h2 className="text-xl font-semibold text-glow pt-4">Additional Comments</h2>
                
                <div>
                  <label htmlFor="additionalComments" className="block text-glow mb-2">
                    Any other comments or suggestions?
                  </label>
                  <textarea
                    id="additionalComments"
                    name="additionalComments"
                    value={formData.additionalComments}
                    onChange={handleInputChange}
                    rows="4"
                    className="w-full bg-surface-light p-3 rounded-lg border border-primary/20 focus:border-primary focus:ring-1 focus:ring-primary transition-all text-text-primary"
                    placeholder="Please share any additional thoughts or suggestions"
                  />
                </div>
              </div>

              {/* Error Message */}
              {errorMessage && (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="p-4 bg-red-500/20 border border-red-500/30 rounded-lg text-red-500"
                >
                  {errorMessage}
                </motion.div>
              )}

              {/* Submit Button */}
              <div className="flex justify-center mt-8">
                <motion.button
                  type="submit"
                  disabled={isSubmitting || !isFormValid()}
                  className={`px-8 py-2 rounded-lg bg-primary text-white font-medium flex items-center justify-center gap-2 w-40 ${
                    (isSubmitting || !isFormValid()) ? 'opacity-50 cursor-not-allowed' : 'hover:bg-primary/80'
                  }`}
                  whileHover={!isSubmitting && isFormValid() ? { scale: 1.02 } : {}}
                  whileTap={!isSubmitting && isFormValid() ? { scale: 0.98 } : {}}
                >
                  {isSubmitting ? (
                    <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white"></div>
                  ) : (
                    <>
                      Submit Feedback
                      <Send size={18} />
                    </>
                  )}
                </motion.button>
              </div>

              {/* Success Message */}
              {submitStatus === 'success' && (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="p-4 bg-green-500/20 border border-green-500/30 rounded-lg text-green-500"
                >
                  Thank you for your valuable feedback! We appreciate your time and insights.
                </motion.div>
              )}
            </form>
          </motion.div>

          {/* Information Cards */}
          <div className="grid md:grid-cols-2 gap-6 mt-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="card-cyber p-6"
            >
              <h3 className="text-lg font-semibold text-glow mb-3">Why Your Feedback Matters</h3>
              <p className="text-text-secondary">
                Your feedback helps us improve MakeFace and deliver a better experience for our global community. We carefully review each submission to enhance our features and services.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              className="card-cyber p-6"
            >
              <h3 className="text-lg font-semibold text-glow mb-3">What Happens Next</h3>
              <p className="text-text-secondary">
                Our team reviews all feedback within 48 hours. While we may not respond individually, your input directly influences our development priorities and future updates.
              </p>
            </motion.div>
          </div>

          {/* Privacy Notice */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="mt-6 text-sm text-text-secondary text-center"
          >
            <p>
              Your privacy is important to us. All feedback is collected and processed in accordance with our{' '}
              <button
                onClick={() => navigate('/privacypolicy')}
                className="text-primary hover:underline"
              >
                Privacy Policy
              </button>
              .
            </p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;