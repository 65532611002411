import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Lock, Eye, Database, Globe, Bell } from 'lucide-react';

const PrivacyPolicy = () => {
  const sections = [
    {
      icon: Shield,
      title: "What Information We Collect",
      content: `We collect the following information to provide and improve our services:

• Account Information: Your name, email address, username, and profile picture
• Content: Photos, videos, messages, and interactions shared on the platform
• Usage Information: Insights into how you interact with the platform, including likes, comments, and views
• Device Information: IP address, browser type, operating system, and device identifiers
• Location Data: General or precise location based on your settings`,
    },
    {
      icon: Lock,
      title: "How We Use Your Information",
      content: `Your information helps us:

• Deliver and personalize your experience on the platform
• Improve platform functionality and safety
• Show relevant content and recommendations
• Conduct research and analyze user trends
• Communicate updates, promotional offers, and notifications
• Ensure compliance with legal obligations`,
    },
    {
      icon: Eye,
      title: "Who Can See Your Information",
      content: `Your privacy settings determine who can see your content:

• Public Accounts: Anyone can view your content and profile information
• Private Accounts: Only approved followers can see your posts
• Personal Messages: Only the intended recipients can view private messages
• Third-Party Services: Your information may be shared with authorized services for platform functionality`,
    },
    {
      icon: Database,
      title: "How We Share Your Information",
      content: `We may share your information:

• With service providers for hosting, analytics, and customer support
• With advertisers for relevant ads, without exposing personal identifiers
• To comply with legal obligations or enforce platform policies
• During business transactions, such as mergers or acquisitions`,
    },
    {
      icon: Globe,
      title: "How We Store and Protect Your Information",
      content: `We prioritize data security:

• Your data is stored on secure servers with industry-standard encryption
• Access to your data is restricted to authorized personnel
• Regular audits and updates ensure compliance with global privacy standards`,
    },
    {
      icon: Bell,
      title: "Your Rights and Choices",
      content: `Your Data, Your Control:

•Get a Copy: Download everything you've shared with us.
•Edit Info: Update or fix details in your account.
•Delete Account: Permanently remove your data and profile.
•Manage Preferences: Adjust settings for ads and notifications.`,
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 30, scale: 0.95 },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 120,
        damping: 15,
      },
    },
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-background via-surface-light to-surface/50 text-text">
      {/* Hero Section */}
      <section className="relative min-h-[70vh] flex items-center justify-center overflow-hidden">
        <div className="absolute inset-0">
          <div
            className="absolute inset-0 bg-[radial-gradient(circle_at_center,rgba(var(--color-primary-rgb),0.2)_0%,transparent_70%)] animate-[pulse_6s_ease-in-out_infinite]"
          />
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{
              opacity: [0.3, 0.6, 0.3],
              scale: [1, 1.2, 1],
              transition: {
                duration: 10,
                repeat: Infinity,
                repeatType: "reverse",
                ease: "easeInOut",
              },
            }}
            className="absolute top-0 left-0 w-[800px] h-[800px] bg-gradient-conic from-primary/40 via-transparent to-transparent blur-[120px]"
          />
        </div>

        <div className="relative z-10 container mx-auto px-6">
          <motion.div
            initial="hidden"
            animate="visible"
            className="text-center"
          >
            <motion.h1
              className="text-4xl md:text-5xl font-extrabold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-primary via-white to-secondary"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              Privacy Policy
            </motion.h1>

            <motion.p
              className="text-base md:text-lg text-text-secondary/90 max-w-3xl mx-auto leading-relaxed font-light"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              Learn how we collect, use, and protect your information while providing personalized experiences.
            </motion.p>
          </motion.div>
        </div>
      </section>

      {/* Content Section */}
      <motion.div
        className="container mx-auto px-4 py-12"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <div className="max-w-5xl mx-auto space-y-8">
          {sections.map((section) => (
            <motion.div
              key={section.title}
              variants={itemVariants}
              className="group"
            >
              <div className="rounded-xl p-8 shadow-lg bg-gradient-to-br from-surface-light/30 to-surface-light/10 backdrop-blur-lg hover:scale-[1.02] transition-all duration-300">
                <div className="flex items-start gap-6">
                  <motion.div
                    className="w-16 h-16 rounded-2xl bg-gradient-to-br from-primary/40 to-secondary/20 flex items-center justify-center"
                    whileHover={{ scale: 1.05 }}
                    transition={{ duration: 0.3 }}
                  >
                    <section.icon className="text-primary w-10 h-10 group-hover:animate-pulse" />
                  </motion.div>

                  <div className="flex-1">
                    <h2 className="text-xl font-semibold mb-4 text-glow group-hover:text-primary transition-colors">
                      {section.title}
                    </h2>
                    <p className="text-sm text-text-secondary/90 leading-relaxed whitespace-pre-line">
                      {section.content}
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default PrivacyPolicy;
