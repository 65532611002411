import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { 
  Mail, 
  MapPin, 
  ArrowRight,
  Calendar,
  Send
} from 'lucide-react';
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';

const Contact = () => {
  const formRef = useRef(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormHighlighted, setIsFormHighlighted] = useState(false);

  const scrollToForm = (presetSubject) => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setFormData(prev => ({
        ...prev,
        subject: presetSubject
      }));
      setIsFormHighlighted(true);
      setTimeout(() => setIsFormHighlighted(false), 1500);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.name || !formData.email || !formData.subject || !formData.message) {
      alert('Please fill in all fields');
      return;
    }

    setIsSubmitting(true);
    try {
      const db = getFirestore();
      await addDoc(collection(db, 'messages'), {
        ...formData,
        timestamp: serverTimestamp()
      });
      
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
      alert('Message sent successfully!');
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send message. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section className="section-cyber min-h-screen py-8 md:py-20">
      {/* Background Elements */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 cyber-dots opacity-10" />
        <div className="absolute top-0 right-0 w-96 h-96 bg-gradient-radial from-primary/20 to-transparent opacity-30 animate-pulse" />
        <div className="absolute bottom-0 left-0 w-96 h-96 bg-gradient-radial from-secondary/20 to-transparent opacity-30 animate-pulse" />
      </div>

      <div className="container relative px-4 md:px-0">
        {/* Section Header */}
        <motion.div 
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="text-center mb-8 md:mb-16"
        >
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold gradient-text mb-4">
            Get In Touch
          </h2>
          <p className="text-base md:text-lg text-text-secondary max-w-2xl mx-auto">
            Ready to revolutionize social connectivity? Let's talk!
          </p>
        </motion.div>

        <div className="grid lg:grid-cols-2 gap-6 md:gap-8">
          {/* Contact Information */}
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            className="space-y-4 md:space-y-6"
          >
            <div className="glass p-4 md:p-6 rounded-xl">
              <h3 className="text-xl md:text-2xl font-bold mb-4 md:mb-6">Contact Information</h3>
              
              <div className="space-y-3 md:space-y-4">
                <motion.div 
                  className="card-cyber p-4 hover:bg-surface-hover transition-colors"
                  whileHover={{ scale: 1.02 }}
                >
                  <div className="flex items-center gap-3 md:gap-4">
                    <div className="w-10 h-10 rounded-full bg-primary/20 flex items-center justify-center flex-shrink-0">
                      <MapPin className="text-primary" size={20} />
                    </div>
                    <div>
                      <h4 className="font-semibold text-glow text-sm md:text-base mb-0.5">Location</h4>
                      <p className="text-text-secondary text-xs md:text-sm">
                        Ganganagar, Katol road, Nagpur
                      </p>
                    </div>
                  </div>
                </motion.div>

                <motion.div 
                  className="card-cyber p-4 hover:bg-surface-hover transition-colors"
                  whileHover={{ scale: 1.02 }}
                >
                  <div className="flex items-center gap-3 md:gap-4">
                    <div className="w-10 h-10 rounded-full bg-primary/20 flex items-center justify-center flex-shrink-0">
                      <Mail className="text-primary" size={20} />
                    </div>
                    <div>
                      <h4 className="font-semibold text-glow text-sm md:text-base mb-0.5">Email</h4>
                      <p className="text-text-secondary text-xs md:text-sm break-all">
                        makefacetechnologies@gmail.com
                      </p>
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>

            {/* Quick Actions */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-4">
              <motion.button 
                className="card-cyber p-4 text-left hover:bg-surface-hover transition-colors"
                whileHover={{ scale: 1.02 }}
                onClick={() => scrollToForm("Schedule a Call Request")}
              >
                <Calendar className="text-primary mb-2" size={20} />
                <h4 className="font-semibold text-glow text-sm md:text-base mb-0.5">Schedule Call</h4>
                <p className="text-text-secondary text-xs md:text-sm">Book a meeting</p>
              </motion.button>
            </div>
          </motion.div>

          {/* Contact Form */}
          <motion.div
            ref={formRef}
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            className={`glass p-4 md:p-6 rounded-xl transition-all duration-300 ${
              isFormHighlighted ? 'ring-2 ring-primary ring-offset-2 ring-offset-background' : ''
            }`}
          >
            <h3 className="text-xl md:text-2xl font-bold mb-4 md:mb-6">Send us a Message</h3>
            
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div className="grid md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-text-secondary mb-1.5">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="input-cyber w-full h-10 text-sm"
                    placeholder="Your name"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-text-secondary mb-1.5">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="input-cyber w-full h-10 text-sm"
                    placeholder="Your email"
                    required
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-text-secondary mb-1.5">
                  Subject
                </label>
                <input
                  type="text"
                  name="subject"
                  value={formData.subject}
                  onChange={handleInputChange}
                  className="input-cyber w-full h-10 text-sm"
                  placeholder="Message subject"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-text-secondary mb-1.5">
                  Message
                </label>
                <textarea
                  rows={4}
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  className="input-cyber w-full resize-none text-sm"
                  placeholder="Your message"
                  required
                />
              </div>

              <button 
                type="submit"
                disabled={isSubmitting}
                className="btn-primary w-full h-10 flex items-center justify-center gap-2 text-sm"
              >
                {isSubmitting ? 'Sending...' : 'Send Message'}
                <Send size={16} />
              </button>
            </form>
          </motion.div>
        </div>

        {/* Call to Action */}
        {/* <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="mt-8 md:mt-16 glass p-4 md:p-8 rounded-xl text-center"
        >
          <h3 className="text-xl md:text-2xl font-bold text-glow mb-3 md:mb-4">
            Interested in Learning More?
          </h3>
          <p className="text-text-secondary text-sm md:text-base max-w-2xl mx-auto mb-4 md:mb-6">
            Discover how we're transforming social connectivity through anime. Get in touch to learn more about our platform.
          </p>
          <div className="flex flex-col md:flex-row justify-center items-center gap-3 md:gap-4">
            <motion.button 
              className="btn-primary w-full md:w-auto"
              whileHover={{ scale: 1.02 }}
              onClick={() => scrollToForm("General Inquiry")}
            >
              <span className="flex items-center justify-center gap-2 text-sm">
                Learn More
                <ArrowRight size={16} />
              </span>
            </motion.button>
          </div>
        </motion.div> */}
      </div>
    </section>
  );
};

export default Contact;