import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { 
  Cloud,
  HeartHandshake,
  GraduationCap,
  Rocket,
  BarChart
} from 'lucide-react';

const CareersPage = () => {
  const navigate = useNavigate();

  const openPositions = [
    {
      title: "Google Cloud Developer",
      department: "Cloud Infrastructure",
      location: "Remote/Nagpur",
      type: "Full-time",
      icon: Cloud,
      requirements: [
        "3+ years experience with Google Cloud Platform",
        "Strong knowledge of cloud architecture and services",
        "Experience with Kubernetes and containerization",
        "Proficiency in Python, Node.js, or Java",
        "Understanding of DevOps practices",
        "Experience with CI/CD pipelines"
      ]
    }
  ];

  const benefits = [
    {
      icon: HeartHandshake,
      title: "Competitive Package",
      description: "Attractive salary with equity options and performance bonuses"
    },
    {
      icon: GraduationCap,
      title: "Learning & Growth",
      description: "Regular workshops, conferences, and learning allowance"
    },
    {
      icon: Rocket,
      title: "Career Development",
      description: "Mentorship programs and clear career progression paths"
    },
    {
      icon: BarChart,
      title: "Work-Life Balance",
      description: "Flexible working hours and remote work options"
    }
  ];

  const handleApply = () => {
    navigate('/apply');
  };

  return (
    <div className="min-h-screen bg-background text-text">
      {/* Hero Section */}
      <section className="relative py-20 overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute inset-0 cyber-dots opacity-10" />
          <div className="absolute top-0 right-0 w-96 h-96 bg-gradient-radial from-primary/20 to-transparent opacity-30 animate-pulse" />
          <div className="absolute bottom-0 left-0 w-96 h-96 bg-gradient-radial from-secondary/20 to-transparent opacity-30 animate-pulse" />
        </div>

        <div className="container mx-auto px-4 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center max-w-3xl mx-auto"
          >
            <h1 className="text-4xl md:text-5xl font-bold gradient-text mb-6">
              Join Our Innovation Journey
            </h1>
            <p className="text-lg text-text-secondary mb-8">
              Be part of a team that's revolutionizing social connectivity through cutting-edge anime-themed experiences.
            </p>
          </motion.div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="py-16 bg-surface/50">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12 text-glow">Why Join MakeFace?</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
            {benefits.map((benefit, index) => (
              <motion.div
                key={benefit.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="card-cyber p-6 text-center"
              >
                <benefit.icon className="w-12 h-12 text-primary mx-auto mb-4" />
                <h3 className="text-xl font-semibold mb-2 text-glow">{benefit.title}</h3>
                <p className="text-text-secondary">{benefit.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Open Positions Section */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12 text-glow">Open Position</h2>
          <div className="max-w-2xl mx-auto">
            {openPositions.map((position) => (
              <motion.div
                key={position.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className="card-cyber p-6"
              >
                <div className="flex items-start gap-4">
                  <div className="w-12 h-12 rounded-full bg-primary/20 flex items-center justify-center flex-shrink-0">
                    <position.icon className="text-primary" size={24} />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold mb-2 text-glow">{position.title}</h3>
                    <div className="flex flex-wrap gap-2 mb-4">
                      <span className="px-3 py-1 rounded-full bg-surface-light text-text-secondary text-sm">
                        {position.department}
                      </span>
                      <span className="px-3 py-1 rounded-full bg-surface-light text-text-secondary text-sm">
                        {position.location}
                      </span>
                      <span className="px-3 py-1 rounded-full bg-surface-light text-text-secondary text-sm">
                        {position.type}
                      </span>
                    </div>
                    <div className="space-y-2">
                      {position.requirements.map((req, idx) => (
                        <div key={idx} className="flex items-center gap-2 text-text-secondary">
                          <div className="w-1.5 h-1.5 rounded-full bg-primary/50" />
                          <span>{req}</span>
                        </div>
                      ))}
                    </div>
                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      className="btn-primary mt-6"
                      onClick={handleApply}
                    >
                      Apply Now
                    </motion.button>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Join Us CTA Section */}
      <section className="py-16 bg-surface/50">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center max-w-3xl mx-auto"
          >
            <h2 className="text-3xl font-bold mb-6 text-glow">Ready to Make an Impact?</h2>
            <p className="text-text-secondary mb-8">
              Join us in shaping the future of social connectivity. We're always looking for passionate individuals to join our team.
            </p>
            
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default CareersPage;