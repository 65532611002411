import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { 
  Shield, 
  Lock, 
  Key, 
  Eye, 
  UserX, 
  Brain,
  Database,
  FileKey,
  RefreshCw,
  Server,
  AlertCircle,
  CheckCircle
} from 'lucide-react';

const SecurityPrivacy = () => {
  const [activeTab, setActiveTab] = useState('security');

  const features = {
    security: {
      title: "Security",
      fullTitle: "Core Security Features",
      description: "Essential protection measures for user safety",
      icon: Shield,
      features: [
        {
          title: "Basic Content Moderation",
          icon: Eye,
          description: "Content filtering and moderation",
          capabilities: [
            "NSFW detection",
            "Spam prevention",
            "Basic harassment detection",
            "Content guidelines"
          ]
        },
        {
          title: "User Protection",
          icon: UserX,
          description: "Foundational identity protection",
          capabilities: [
            "Profile privacy settings",
            "Basic data encryption",
            "Profile visibility control",
            "Report mechanisms"
          ]
        }
      ]
    },
    privacy: {
      title: "Privacy",
      fullTitle: "Privacy Controls",
      description: "User-centric privacy settings",
      icon: Lock,
      features: [
        {
          title: "Profile Privacy",
          icon: Lock,
          description: "Control over your data visibility",
          capabilities: [
            "Profile visibility settings",
            "Content access controls",
            "Connection permissions",
            "Data sharing options"
          ]
        },
        {
          title: "Data Protection",
          icon: Database,
          description: "Standard data security measures",
          capabilities: [
            "Secure data storage",
            "Regular backups",
            "Data retention policies",
            "Privacy policy compliance"
          ]
        }
      ]
    },
    future: {
      title: "Future",
      fullTitle: "Future Security Roadmap",
      description: "Planned security enhancements",
      icon: Brain,
      features: [
        {
          title: "Advanced AI Security",
          icon: Brain,
          description: "Future AI-powered protection",
          capabilities: [
            "AI threat detection",
            "Behavioral analysis",
            "Advanced content moderation",
            "Automated responses"
          ]
        },
        {
          title: "Enterprise Security",
          icon: Shield,
          description: "Upcoming enterprise features",
          capabilities: [
            "End-to-end encryption",
            "Advanced authentication",
            "Security certifications",
            "24/7 monitoring"
          ]
        }
      ]
    }
  };

  const currentCertifications = [
    { name: "Basic Security Standards", status: "Implemented", icon: Shield },
    { name: "Data Privacy Compliance", status: "In Progress", icon: Lock }
  ];

  const futureCertifications = [
    { name: "ISO 27001", status: "Planned", icon: Shield },
    { name: "SOC 2", status: "Future Goal", icon: CheckCircle }
  ];

  return (
    <section id="security" className="section-cyber py-12 md:py-20">
      {/* Background elements */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 cyber-dots opacity-10" />
        <div className="absolute top-0 right-0 w-96 h-96 bg-gradient-radial from-primary/20 to-transparent opacity-30 animate-pulse" />
        <div className="absolute bottom-0 left-0 w-96 h-96 bg-gradient-radial from-secondary/20 to-transparent opacity-30 animate-pulse" />
      </div>

      <div className="container relative">
        {/* Header */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="text-center mb-8 md:mb-16 px-4 md:px-0"
        >
          <h2 className="cyber-heading mb-4">
            <span className="gradient-text">Security & Privacy</span>
          </h2>
          <p className="text-text-secondary text-lg md:text-xl max-w-2xl mx-auto">
            Building trust through robust security measures
          </p>
        </motion.div>

        {/* Feature Navigation - Mobile Scroll */}
        <div className="overflow-x-auto mb-8 md:mb-12 px-4 -mx-4 md:mx-0 md:px-0">
          <div className="flex md:justify-center min-w-min md:min-w-0 gap-2 md:gap-4 px-4 md:px-0">
            {Object.entries(features).map(([key, section]) => (
              <button
                key={key}
                onClick={() => setActiveTab(key)}
                className={`whitespace-nowrap flex items-center gap-2 px-4 md:px-6 py-3 rounded-lg transition-all text-sm md:text-base
                  ${activeTab === key ? 'btn-primary' : 'btn-secondary'}`}
              >
                <section.icon size={20} />
                <span className="hidden md:inline">{section.fullTitle}</span>
                <span className="md:hidden">{section.title}</span>
              </button>
            ))}
          </div>
        </div>

        {/* Feature Content */}
        <motion.div
          key={activeTab}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="grid lg:grid-cols-2 gap-8 md:gap-12 px-4 md:px-0"
        >
          {/* Feature Description */}
          <div>
            <h3 className="cyber-subheading mb-4">
              {features[activeTab].fullTitle}
            </h3>
            <p className="text-text-secondary mb-6 md:mb-8">
              {features[activeTab].description}
            </p>

            <div className="space-y-4 md:space-y-6">
              {features[activeTab].features.map((feature) => (
                <motion.div
                  key={feature.title}
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  className="card-cyber p-4 md:p-6"
                >
                  <div className="flex items-start md:items-center gap-4 mb-4">
                    <div className="w-10 h-10 md:w-12 md:h-12 rounded-full bg-primary/20 flex items-center justify-center flex-shrink-0">
                      <feature.icon className="text-primary" size={20} />
                    </div>
                    <div>
                      <h4 className="font-bold text-glow text-base md:text-lg">{feature.title}</h4>
                      <p className="text-text-secondary text-sm md:text-base">{feature.description}</p>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-4">
                    {feature.capabilities.map((capability, index) => (
                      <div key={index} className="flex items-center gap-2">
                        <div className="w-1.5 h-1.5 rounded-full bg-primary flex-shrink-0" />
                        <span className="text-text-secondary text-sm md:text-base">{capability}</span>
                      </div>
                    ))}
                  </div>
                </motion.div>
              ))}
            </div>
          </div>

          {/* Interactive Visualization */}
          <div className="space-y-6 md:space-y-8">
            {/* Current Security Status */}
            <div className="glass p-4 md:p-8 rounded-xl">
              <h3 className="text-xl md:text-2xl font-bold mb-4 md:mb-6">Current Status</h3>
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <span className="text-text-secondary text-sm md:text-base">Basic Security</span>
                  <span className="text-success flex items-center gap-2">
                    <CheckCircle size={16} />
                    <span className="text-sm md:text-base">Implemented</span>
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <span className="text-text-secondary text-sm md:text-base">Privacy Controls</span>
                  <span className="text-success flex items-center gap-2">
                    <CheckCircle size={16} />
                    <span className="text-sm md:text-base">Active</span>
                  </span>
                </div>
              </div>
            </div>

            {/* Current & Future Certifications */}
            <div className="glass p-4 md:p-8 rounded-xl">
              <h3 className="text-xl md:text-2xl font-bold mb-4 md:mb-6">Security Roadmap</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {currentCertifications.map((cert) => (
                  <div key={cert.name} className="card-cyber p-4">
                    <div className="flex items-center gap-3 mb-2">
                      <cert.icon className="text-primary" size={20} />
                      <span className="font-semibold text-glow text-sm md:text-base">{cert.name}</span>
                    </div>
                    <span className="text-sm text-success">{cert.status}</span>
                  </div>
                ))}
                {futureCertifications.map((cert) => (
                  <div key={cert.name} className="card-cyber p-4">
                    <div className="flex items-center gap-3 mb-2">
                      <cert.icon className="text-primary" size={20} />
                      <span className="font-semibold text-glow text-sm md:text-base">{cert.name}</span>
                    </div>
                    <span className="text-sm text-warning">{cert.status}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default SecurityPrivacy;