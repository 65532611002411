import React from 'react';

const AboutUsPage = () => {
  return (
    <div className="container mx-auto px-4 py-8 pt-20">      <h1 className="text-4xl md:text-5xl font-extrabold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-primary via-white to-secondary text-center">
        About Us
      </h1>

      <p className="text-text-secondary text-lg text-center mb-12">
        Welcome to <span className="font-semibold text-glow">MakeFace</span>, where we redefine social connectivity through anime-inspired experiences. Our platform fosters a unique blend of creativity, community, and innovation to bring like-minded individuals together.
      </p>

      <section className="mb-12">
        <h2 className="text-2xl font-bold mb-4 text-glow">Our Mission</h2>
        <p className="text-text-secondary text-lg leading-relaxed">
          At MakeFace, our mission is to revolutionize the way people connect by creating an engaging, anime-themed social media platform. We strive to empower users to express themselves creatively, share their passions, and build meaningful relationships in a vibrant, inclusive digital environment.
        </p>
      </section>

      <section className="mb-12">
        <h2 className="text-2xl font-bold mb-4 text-glow">Meet the Team</h2>
        <p className="text-text-secondary text-lg leading-relaxed mb-6">
          Our team is a group of passionate individuals who share a common love for anime, technology, and community building. From experienced developers to visionary designers, we bring together diverse talents to create an exceptional platform for our users.
        </p>
        <ul className="list-disc list-inside text-text-secondary">
          <li><strong>Pratik Petkar</strong> – Founder & Product development</li>
          <li><strong>Prajwal Sontakke</strong> – Technical operations and infrastructure</li>
          <li><strong>Zeeshan Ahmed</strong> – UI/UX Designer</li>
          <li><strong>Manish Umarvaishya</strong> -Software Development and application testing </li>
          <li><strong>Apeksha Meshram</strong> -Feature implementation and application testing </li>
        </ul>
      </section>

      <section className="mb-12">
        <h2 className="text-2xl font-bold mb-4 text-glow">Our Core Values</h2>
        <p className="text-text-secondary text-lg leading-relaxed mb-6">
          We are guided by values that reflect our commitment to our users and the community:
        </p>
        <div className="grid md:grid-cols-2 gap-6">
          <div className="p-4 border rounded-lg shadow-md bg-surface-light">
            <h3 className="text-lg font-semibold text-primary">Creativity</h3>
            <p className="text-text-secondary">
              Encouraging self-expression and innovation through unique anime-inspired themes.
            </p>
          </div>
          <div className="p-4 border rounded-lg shadow-md bg-surface-light">
            <h3 className="text-lg font-semibold text-primary">Inclusivity</h3>
            <p className="text-text-secondary">
              Building a diverse and welcoming community where everyone belongs.
            </p>
          </div>
          <div className="p-4 border rounded-lg shadow-md bg-surface-light">
            <h3 className="text-lg font-semibold text-primary">Innovation</h3>
            <p className="text-text-secondary">
              Pioneering cutting-edge technology to deliver a seamless user experience.
            </p>
          </div>
          <div className="p-4 border rounded-lg shadow-md bg-surface-light">
            <h3 className="text-lg font-semibold text-primary">Connection</h3>
            <p className="text-text-secondary">
              Facilitating meaningful interactions and lasting relationships.
            </p>
          </div>
        </div>
      </section>

      <section className="mb-12">
        <h2 className="text-2xl font-bold mb-4 text-glow">Our Goals</h2>
        <p className="text-text-secondary text-lg leading-relaxed">
          We aim to achieve the following:
        </p>
        <ul className="list-disc list-inside text-text-secondary mt-4">
          <li>Grow our community to over 100,000 active users within the first year.</li>
          <li>Collaborate with anime creators and influencers to enhance content.</li>
          <li>Continuously innovate to provide a secure, user-friendly platform.</li>
        </ul>
      </section>

      <section className="text-center">
        <h2 className="text-2xl font-bold mb-4 text-primary">Join Us on Our Journey!</h2>
        <p className="text-text-secondary text-lg mb-6">
          Be a part of our growing community and experience the magic of MakeFace.
        </p>
      </section>
    </div>
  );
};

export default AboutUsPage;