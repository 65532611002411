import React, { useEffect } from 'react';
import { ThemeProvider } from './styles/theme';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Hero from './components/Hero';
import ProductDemo from './components/ProductDemo';
import SecurityPrivacy from './components/SecurityPrivacy';
import DevelopmentProgress from './components/DevelopmentProgress';
import TechnologyStack from './components/TechnologyStack';
import CompetitiveAnalysis from './components/CompetitiveAnalysis';
import FounderVision from './components/FounderVision';
import Contact from './components/Contact';
import Footer from './components/Footer';
import AboutUsPage from './components/Aboutuspage';
import CareersPage from './components/CareersPage';
import ApplicationForm from './components/ApplicationForm';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import Feedback from './components/Feedback'; 
import Navigation from './components/Navigation'; 
import './styles/global.css';

const HomePage = () => (
  <>
    <Hero />
    <ProductDemo />
    <SecurityPrivacy />
    <DevelopmentProgress />
    <TechnologyStack />
    <CompetitiveAnalysis />
    <FounderVision />
    <Contact />
  </>
);

const App = () => {
  const location = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <ThemeProvider>
      <div className="min-h-screen bg-background text-text">
      <Navigation /> 
        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/aboutus" element={<AboutUsPage />} />
            <Route path="/careers" element={<CareersPage />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/apply" element={<ApplicationForm />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/termsofservice" element={<TermsOfService />} />
            <Route path="/feedback" element={<Feedback />} />  
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </main>
        {location.pathname === '/' && <Footer />}
      </div>
    </ThemeProvider>
  );
};

export default App;