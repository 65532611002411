import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { Upload, Cloud } from 'lucide-react';
import { db, auth, storage } from '../config/firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const ApplicationForm = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    experience: '',
    currentCompany: '',
    noticePeriod: '',
    linkedIn: '',
    coverLetter: ''
  });
  
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (
        file.type === 'application/pdf' ||
        file.type === 'application/msword' ||
        file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ) {
        if (file.size <= 5 * 1024 * 1024) {
          setSelectedFile(file);
          setUploadStatus(`File selected: ${file.name}`);
        } else {
          setUploadStatus('Error: File size should be less than 5MB');
          setSelectedFile(null);
        }
      } else {
        setUploadStatus('Error: Please upload a PDF or Word document only');
        setSelectedFile(null);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      setUploadStatus('Please select a resume file first');
      return;
    }

    setIsLoading(true);
    try {
      if (!auth.currentUser) {
        throw new Error('Please sign in to submit application');
      }

      // Get file extension
      const fileExtension = selectedFile.name.split('.').pop();
      
      try {
        // Simplified storage path: resume/email.extension
        const sanitizedEmail = formData.email.toLowerCase().replace(/[.#$[\]]/g, '_');
        const fileName = `${sanitizedEmail}.${fileExtension}`;
        const storageRef = ref(storage, `resume/${fileName}`);

        console.log('Starting file upload...');
        const uploadResult = await uploadBytes(storageRef, selectedFile);
        console.log('File uploaded, getting URL...');
        const downloadURL = await getDownloadURL(uploadResult.ref);
        console.log('Got download URL:', downloadURL);

        const applicationData = {
          ...formData,
          resume: {
            fileName: fileName,
            fileType: selectedFile.type,
            fileUrl: downloadURL,
            uploadDate: new Date().toISOString(),
            storagePath: `resume/${fileName}`
          },
          userId: auth.currentUser.uid,
          position: "Google Cloud Developer",
          status: "Applied",
          appliedAt: serverTimestamp(),
        };

        console.log('Saving to Firestore...');
        const docRef = await addDoc(collection(db, 'applied_jobs'), applicationData);
        console.log("Application submitted with ID:", docRef.id);
        
        setIsSubmitted(true);
        setUploadStatus('Application submitted successfully!');

      } catch (uploadError) {
        console.error('Upload error:', uploadError);
        if (uploadError.code === 'storage/unauthorized') {
          throw new Error('Storage access denied. Please check permissions.');
        } else {
          throw new Error(`Upload failed: ${uploadError.message}`);
        }
      }

    } catch (error) {
      console.error('Submission error:', error);
      setUploadStatus(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  if (isSubmitted) {
    return (
      <div className="min-h-screen bg-background text-text flex items-center justify-center">
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          className="card-cyber p-6 text-center max-w-sm mx-4"
        >
          <Cloud className="w-12 h-12 text-primary mx-auto mb-3" />
          <h2 className="text-xl font-bold text-glow mb-3">Application Submitted Successfully!</h2>
          <p className="text-sm text-text-secondary mb-4">
            Thank you for applying for the Google Cloud Developer position. We will review your application and get back to you soon.
          </p>
          <motion.button
            whileHover={{ scale: 1.02 }}
            className="btn-primary text-sm py-2 px-4 max-w-xs mx-auto"
            onClick={() => window.history.back()}
          >
            Back to Careers
          </motion.button>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-background text-text py-12">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-xl mx-auto"
        >
          <div className="card-cyber p-6">
            <h2 className="text-2xl font-bold text-glow mb-6 text-center">
              Apply for Google Cloud Developer
            </h2>
            
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-xs font-medium mb-1">Full Name</label>
                  <input
                    type="text"
                    name="fullName"
                    required
                    className="w-full bg-surface-light p-2 rounded-md border border-primary/20 focus:border-primary outline-none text-sm"
                    value={formData.fullName}
                    onChange={handleInputChange}
                  />
                </div>
                
                <div>
                  <label className="block text-xs font-medium mb-1">Email</label>
                  <input
                    type="email"
                    name="email"
                    required
                    className="w-full bg-surface-light p-2 rounded-md border border-primary/20 focus:border-primary outline-none text-sm"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </div>
                
                <div>
                  <label className="block text-xs font-medium mb-1">Phone Number</label>
                  <input
                    type="tel"
                    name="phone"
                    required
                    className="w-full bg-surface-light p-2 rounded-md border border-primary/20 focus:border-primary outline-none text-sm"
                    value={formData.phone}
                    onChange={handleInputChange}
                  />
                </div>
                
                <div>
                  <label className="block text-xs font-medium mb-1">Years of Experience</label>
                  <input
                    type="text"
                    name="experience"
                    required
                    className="w-full bg-surface-light p-2 rounded-md border border-primary/20 focus:border-primary outline-none text-sm"
                    value={formData.experience}
                    onChange={handleInputChange}
                  />
                </div>
                
                <div>
                  <label className="block text-xs font-medium mb-1">Current Company</label>
                  <input
                    type="text"
                    name="currentCompany"
                    className="w-full bg-surface-light p-2 rounded-md border border-primary/20 focus:border-primary outline-none text-sm"
                    value={formData.currentCompany}
                    onChange={handleInputChange}
                  />
                </div>
                
                <div>
                  <label className="block text-xs font-medium mb-1">Notice Period (in days)</label>
                  <input
                    type="text"
                    name="noticePeriod"
                    required
                    className="w-full bg-surface-light p-2 rounded-md border border-primary/20 focus:border-primary outline-none text-sm"
                    value={formData.noticePeriod}
                    onChange={handleInputChange}
                  />
                </div>
                
                <div className="md:col-span-2">
                  <label className="block text-xs font-medium mb-1">LinkedIn Profile</label>
                  <input
                    type="url"
                    name="linkedIn"
                    className="w-full bg-surface-light p-2 rounded-md border border-primary/20 focus:border-primary outline-none text-sm"
                    value={formData.linkedIn}
                    onChange={handleInputChange}
                  />
                </div>
                
                <div className="md:col-span-2">
                  <label className="block text-xs font-medium mb-1">Cover Letter</label>
                  <textarea
                    name="coverLetter"
                    rows="3"
                    className="w-full bg-surface-light p-2 rounded-md border border-primary/20 focus:border-primary outline-none text-sm"
                    value={formData.coverLetter}
                    onChange={handleInputChange}
                  ></textarea>
                </div>
              </div>

              <div className="space-y-3">
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileSelect}
                  accept=".pdf,.doc,.docx"
                  className="hidden"
                />

                <div className="flex flex-col items-center gap-3">
                  <motion.button
                    type="button"
                    whileHover={{ scale: 1.02 }}
                    className="btn-primary max-w-xs mx-auto flex items-center justify-center gap-2 py-2 text-sm px-4"
                    onClick={() => fileInputRef.current.click()}
                    disabled={isLoading}
                  >
                    <Upload className="w-4 h-4" />
                    Select Resume
                  </motion.button>

                  {selectedFile && (
                    <div className="card-cyber p-2 max-w-xs mx-auto w-full">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-2">
                          <div className="text-left">
                            <p className="text-xs font-medium text-glow truncate">
                              {selectedFile.name}
                            </p>
                            <p className="text-xs text-text-secondary">
                              {(selectedFile.size / 1024 / 1024).toFixed(2)} MB
                            </p>
                          </div>
                        </div>
                        <button
                          type="button"
                          onClick={() => {
                            setSelectedFile(null);
                            setUploadStatus('');
                            fileInputRef.current.value = '';
                          }}
                          className="p-1 hover:bg-surface-light rounded-full transition-colors text-sm"
                          aria-label="Remove file"
                          disabled={isLoading}
                        >
                          ×
                        </button>
                      </div>
                    </div>
                  )}

                  {uploadStatus && (
                    <p className={`text-xs ${uploadStatus.includes('Error') ? 'text-red-500' : 'text-green-500'} text-center`}>
                      {uploadStatus}
                    </p>
                  )}
                </div>

                <motion.button
                  type="submit"
                  whileHover={{ scale: 1.02 }}
                  className="btn-primary max-w-xs mx-auto block py-2 text-sm px-4"
                  disabled={isLoading}
                >
                  {isLoading ? 'Submitting...' : 'Submit Application'}
                </motion.button>
              </div>
            </form>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default ApplicationForm;