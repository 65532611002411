import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const ImageSlider = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  
  const images = [
    {
      src: '/assets/images/Home_page.jpg',
      title: 'Home Page',
      description: 'Your personalized dashboard'
    },
    {
      src: '/assets/images/Global_feed.jpg',
      title: 'Global Feed',
      description: 'Connect with the community'
    },
    {
      src: '/assets/images/Live_room.jpg',
      title: 'Live Room',
      description: 'Real-time interactions'
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full h-full relative rounded-xl overflow-hidden">
      <motion.img
        key={currentImageIndex}
        src={images[currentImageIndex].src}
        alt={images[currentImageIndex].title}
        className="w-full h-full object-cover"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      />
      <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/80 to-transparent">
        <h4 className="text-lg md:text-xl font-bold text-white mb-2">
          {images[currentImageIndex].title}
        </h4>
        <p className="text-sm md:text-base text-white/80">
          {images[currentImageIndex].description}
        </p>
      </div>
    </div>
  );
};

export default ImageSlider;