import React from 'react';
import { motion } from 'framer-motion';
import { 
  Scale, 
  UserPlus, 
  ShieldCheck, 
  CreditCard, 
  MessagesSquare, 
  AlertCircle, 
  Ban 
} from 'lucide-react';

const TermsOfService = () => {
  const sections = [
    {
      icon: Scale,
      title: "Introduction",
      content: `Welcome to MakeFace! By using our platform, you agree to our Terms of Service, which govern your relationship with MakeFace and outline the rules for using our services. If you disagree with any part of these terms, we kindly ask you to reconsider using the platform. Feel free to reach out. 
`,
    },
    {
      icon: UserPlus,
      title: "Eligibility",
      content: `To use MakeFace, you must:

• Be at least 13 years old or meet the minimum age requirement in your jurisdiction.
• Provide accurate and up-to-date registration information.
• Ensure that your account has not been previously disabled for violations of our terms.

If you act on behalf of a business, you represent and warrant that you have the authority to bind that business to these terms.`,
    },
    {
      icon: ShieldCheck,
      title: "User Responsibilities",
      content: `By using MakeFace, you agree to:

• Use the platform in compliance with all applicable laws and regulations.
• Avoid sharing content that is illegal, harmful, or violates the rights of others.
• Respect the privacy and intellectual property of other users.
• Refrain from engaging in fraudulent, misleading, or manipulative behavior.
• Not use bots, crawlers, or other automated tools to scrape or manipulate the platform.`,
    },
    {
      icon: MessagesSquare,
      title: "Content Ownership and Licensing",
      content: `You retain full ownership of all content you post on MakeFace. By sharing content, you confirm that it complies with all applicable laws and does not infringe on the rights of others.  

As the owner of your content, you are solely responsible for its accuracy and legality. MakeFace reserves the right to remove content that violates our community guidelines or legal obligations.
`,
    },
    {
      icon: ShieldCheck,
      title: "Community Guidelines",
      content: `To maintain a safe and welcoming community, users must not:

• Share content that is abusive, hateful, discriminatory, or violent.
• Engage in harassment, bullying, or threats.
• Promote illegal activities or misinformation.
• Post nudity, sexually explicit content, or graphic violence unless otherwise allowed by the platform's policies.

Violations may result in removal of content, suspension, or termination of accounts.`,
    },
    {
      icon: CreditCard,
      title: "Payment Terms",
      content: `MakeFace offers optional paid services and subscriptions. All payments are processed securely, and users are responsible for maintaining accurate billing information.

Payments are non-refundable unless required by law. Subscriptions renew automatically unless cancelled by user in advance.`,
    },
    {
      icon: Ban,
      title: "Platform Availability",
      content: `Platform Availability
At MakeFace, we strive to ensure our platform is available and accessible at all times, providing you with a seamless experience. However, we cannot guarantee uninterrupted, error-free, or continuous service due to factors beyond our control, such as maintenance, updates, or unforeseen technical issues.

To enhance your user experience, we may periodically update, modify, or discontinue certain features or services. These changes may be implemented without prior notice. While we work diligently to minimize disruptions, we appreciate your understanding and patience during such times.
`,
    },
    {
      icon: AlertCircle,
      title: "Termination of Account",
      content: `We reserve the right to suspend or terminate your account if:

• You violate these terms or applicable laws.
• Your actions harm other users or the platform.
• Required by legal obligations.

Upon termination, your license to use MakeFace ends, and your account data may be deleted.`,
    },
    {
      icon: Scale,
      title: "Limitations of Liability",
      content: `MakeFace is provided "as is," and we do not guarantee uninterrupted access or error-free service.

We are not responsible for user-generated content, third-party services, or damages resulting from your use of the platform.

Our liability is limited to the fullest extent permitted by law.`,
    },
    {
      icon: ShieldCheck,
      title: "Changes to Terms",
      content: `We may update these Terms of Service from time to time. Significant changes will be communicated, and continued use of MakeFace constitutes your acceptance of the revised terms.`,
    },
    {
      icon: MessagesSquare,
      title: "Dispute Resolution",
      content: `Any disputes arising from these terms will be resolved under the laws of India. If disputes cannot be resolved informally, they may be handled by the courts of High Court or Supreme court of India.`,
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 30, scale: 0.95 },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 120,
        damping: 15,
      },
    },
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-background via-surface-light to-surface/50 text-text">
      {/* Hero Section */}
      <section className="relative min-h-[70vh] flex items-center justify-center overflow-hidden">
        <div className="absolute inset-0">
          <div
            className="absolute inset-0 bg-[radial-gradient(circle_at_center,rgba(var(--color-primary-rgb),0.2)_0%,transparent_70%)] animate-[pulse_6s_ease-in-out_infinite]"
          />
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{
              opacity: [0.3, 0.6, 0.3],
              scale: [1, 1.2, 1],
              transition: {
                duration: 10,
                repeat: Infinity,
                repeatType: "reverse",
                ease: "easeInOut",
              },
            }}
            className="absolute top-0 left-0 w-[800px] h-[800px] bg-gradient-conic from-primary/40 via-transparent to-transparent blur-[120px]"
          />
        </div>

        <div className="relative z-10 container mx-auto px-6">
          <motion.div
            initial="hidden"
            animate="visible"
            className="text-center"
          >
            <motion.h1
              className="text-4xl md:text-5xl font-extrabold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-primary via-white to-secondary"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              Terms of Service
            </motion.h1>

            <motion.p
              className="text-base md:text-lg text-text-secondary/90 max-w-3xl mx-auto leading-relaxed font-light"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              Review our rules and policies to ensure a safe and respectful environment for all users.
            </motion.p>
          </motion.div>
        </div>
      </section>

      {/* Content Section */}
      <motion.div
        className="container mx-auto px-4 py-12"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <div className="max-w-5xl mx-auto space-y-8">
          {sections.map((section) => (
            <motion.div
              key={section.title}
              variants={itemVariants}
              className="group"
            >
              <div className="rounded-xl p-8 shadow-lg bg-gradient-to-br from-surface-light/30 to-surface-light/10 backdrop-blur-lg hover:scale-[1.02] transition-all duration-300">
                <div className="flex items-start gap-6">
                  <motion.div
                    className="w-16 h-16 rounded-2xl bg-gradient-to-br from-primary/40 to-secondary/20 flex items-center justify-center"
                    whileHover={{ scale: 1.05 }}
                    transition={{ duration: 0.3 }}
                  >
                    <section.icon className="text-primary w-10 h-10 group-hover:animate-pulse" />
                  </motion.div>

                  <div className="flex-1">
                    <h2 className="text-xl font-semibold mb-4 text-glow group-hover:text-primary transition-colors">
                      {section.title}
                    </h2>
                    <p className="text-sm text-text-secondary/90 leading-relaxed whitespace-pre-line">
                      {section.content}
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default TermsOfService;
