import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, X, Menu, Home } from 'lucide-react';
import logo from '../assets/Makeface logo.png';

const Navigation = () => {
  const navigate = useNavigate();
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [mobileActiveDropdown, setMobileActiveDropdown] = useState(null);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  const handleNavigation = (path) => {
    navigate(path);
    setActiveDropdown(null);
    setIsMobileMenuOpen(false);
    setMobileActiveDropdown(null);
  
    if (path.includes('#')) {
      const [, sectionId] = path.split('#');
      const section = document.getElementById(sectionId);
      if (section) {
        setTimeout(() => {
          section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 100);
      }
    } else {
      window.scrollTo(0, 0);
    }
  };

  const scrollToSection = (section) => {
    const sections = document.getElementsByClassName('section-cyber');
    const sectionMap = {
      'features': 1,
      'security': 3,
      'team': 8,
      'contact': 9,
      'development': 4,
      'technology': 6,
    };

    const index = sectionMap[section];
    if (index !== undefined && sections[index]) {
      sections[index].scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    setActiveDropdown(null);
    setIsMobileMenuOpen(false);
    setMobileActiveDropdown(null);
  };

  const navItems = {
    "Product": [
      { label: "Features", action: () => scrollToSection('features') },
      { label: "Development", action: () => handleNavigation('/#development') },
      { label: "Security", action: () => handleNavigation('/#security') },
      { label: "Team", action: () => handleNavigation('/#team') },
    ],
    "Company": [
      { label: "About Us", action: () => handleNavigation('/aboutus') },
      { label: "Careers", action: () => handleNavigation('/careers') },
      { label: "Contact", action: () => handleNavigation('/contact') },
      { label: "Feedback", action: () => handleNavigation('/feedback') }
    ],
    "Resources": [
        { label: "Technology", action: () => handleNavigation('/#technology') },
      { label: "Privacy Policy", action: () => handleNavigation('/privacypolicy') },
      { label: "Terms of Service", action: () => handleNavigation('/termsofservice') },
    ]
  };

  const toggleMobileDropdown = (title) => {
    setMobileActiveDropdown(mobileActiveDropdown === title ? null : title);
  };

  return (
    <nav className={`fixed left-0 right-0 z-50 bg-surface/80 backdrop-blur-md border-b border-primary/20 transition-transform duration-300 ${
      visible ? 'top-0 transform translate-y-0' : 'transform -translate-y-full'
    }`}>
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <div className="flex items-center">
            <img 
              src={logo} 
              alt="MakeFace Logo" 
              className="h-12 w-auto cursor-pointer transition-transform hover:scale-105"
              onClick={() => handleNavigation('/')}
            />
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center gap-8">
            {/* Home Button */}
            <button
              onClick={() => handleNavigation('/')}
              className="flex items-center gap-1 px-4 py-2 text-text-secondary hover:text-primary transition-colors"
            >
              <Home size={20} className="mr-1" />
              Home
            </button>

            {Object.entries(navItems).map(([title, items]) => (
              <div
                key={title}
                className="relative"
                onMouseEnter={() => setActiveDropdown(title)}
                onMouseLeave={() => setActiveDropdown(null)}
              >
                <button className="flex items-center gap-1 px-2 py-1 text-text-secondary hover:text-primary transition-colors">
                  {title}
                  <ChevronDown size={16} className={`transform transition-transform ${
                    activeDropdown === title ? 'rotate-180' : ''
                  }`} />
                </button>

                <AnimatePresence>
                  {activeDropdown === title && (
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 10 }}
                      className="absolute top-full left-0 mt-2 py-2 w-48 bg-surface rounded-lg shadow-lg border border-primary/20 overflow-hidden"
                    >
                      {items.map((item) => (
                        <button
                          key={item.label}
                          onClick={item.action}
                          className="w-full text-left px-4 py-2 text-text-secondary hover:bg-primary/10 hover:text-primary transition-colors"
                        >
                          {item.label}
                        </button>
                      ))}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            ))}
          </div>

          {/* Mobile Menu Button */}
          <button 
            className="md:hidden p-2 text-text-secondary hover:text-primary"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>

        {/* Mobile Menu */}
        <AnimatePresence>
          {isMobileMenuOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              className="md:hidden overflow-hidden bg-surface border-t border-primary/20"
            >
              <div className="py-4 space-y-2">
                {/* Home Option in Mobile Menu */}
                <div className="px-4">
                  <button
                    onClick={() => handleNavigation('/')}
                    className="flex items-center w-full py-2 text-text-secondary hover:text-primary"
                  >
                    <Home size={20} className="mr-2" />
                    Home
                  </button>
                </div>

                {Object.entries(navItems).map(([title, items]) => (
                  <div key={title} className="px-4">
                    <button
                      className="flex items-center justify-between w-full py-2 text-text-secondary"
                      onClick={() => toggleMobileDropdown(title)}
                    >
                      <span>{title}</span>
                      <ChevronDown
                        size={16}
                        className={`transform transition-transform ${
                          mobileActiveDropdown === title ? 'rotate-180' : ''
                        }`}
                      />
                    </button>
                    
                    <AnimatePresence>
                      {mobileActiveDropdown === title && (
                        <motion.div
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: 'auto' }}
                          exit={{ opacity: 0, height: 0 }}
                          className="ml-4 overflow-hidden"
                        >
                          {items.map((item) => (
                            <button
                              key={item.label}
                              onClick={item.action}
                              className="w-full text-left py-2 px-4 text-text-secondary hover:text-primary transition-colors"
                            >
                              {item.label}
                            </button>
                          ))}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </nav>
  );
};

export default Navigation;