import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowRight, Shield, Users, TrendingUp, X, Check } from 'lucide-react';
import { db } from '../config/firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import ImageSlider from './ImageSlider';
const Hero = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [showEarlyAccessForm, setShowEarlyAccessForm] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    profession: '',
    interests: [],
    reason: ''
  });
  const [loading, setLoading] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const stats = [
    { label: 'Development Complete', value: '100%', icon: TrendingUp },
    { label: 'Development Time', value: '10 Months', icon: Users }
  ];

  const interestOptions = [
    'Anime Community',
    'Social Networking',
    'Content Creation',
    'Privacy Features',
    'Multi-Profile System'
  ];

  const handleInterestToggle = (interest) => {
    setFormData(prev => ({
      ...prev,
      interests: prev.interests.includes(interest)
        ? prev.interests.filter(i => i !== interest)
        : [...prev.interests, interest]
    }));
  };

  const validateForm = () => {
    const { name, email, profession, reason } = formData;
    if (!name.trim() || !email.trim() || !profession.trim() || !reason.trim()) {
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return false;
    }
    return true;
  };

  const resetForm = () => {
    setFormData({
      name: '',
      email: '',
      profession: '',
      interests: [],
      reason: ''
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      setSubmitStatus('validation_error');
      return;
    }

    setLoading(true);
    try {
      const docData = {
        ...formData,
        timestamp: serverTimestamp(),
        status: 'pending',
        submittedAt: new Date().toISOString(),
        lastUpdated: new Date().toISOString(),
        metadata: {
          userAgent: navigator.userAgent,
          platform: navigator.platform,
          language: navigator.language
        }
      };

      const docRef = await addDoc(collection(db, 'early_requests'), docData);
      console.log('Document written with ID:', docRef.id);
      setSubmitStatus('success');
      resetForm();

      setTimeout(() => {
        setShowEarlyAccessForm(false);
        setSubmitStatus(null);
      }, 2000);

    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error');
      
      if (error.code) console.error('Error code:', error.code);
      if (error.message) console.error('Error message:', error.message);
      
    } finally {
      setLoading(false);
    }
  };

  const renderFormError = () => {
    switch (submitStatus) {
      case 'validation_error':
        return <p className="text-error text-sm text-center">Please fill in all required fields correctly.</p>;
      case 'error':
        return <p className="text-error text-sm text-center">An error occurred. Please try again.</p>;
      default:
        return null;
    }
  };

  return (
    <section className="section-cyber min-h-screen flex items-center pt-24 pb-12 md:py-0 md:pt-16">
      {/* Background elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute inset-0 cyber-dots opacity-10" />
        <div className="absolute -top-1/2 -right-1/2 w-[100rem] h-[100rem] bg-gradient-radial from-primary/10 to-transparent opacity-30 animate-pulse" />
        <div className="absolute -bottom-1/2 -left-1/2 w-[100rem] h-[100rem] bg-gradient-radial from-secondary/10 to-transparent opacity-30 animate-pulse" />
      </div>

      <div className="container relative">
        <div className="grid lg:grid-cols-2 gap-8 md:gap-12 items-center">
          {/* Left content */}
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="relative"
          >
            <div className="absolute -top-20 -left-20 w-40 h-40 bg-primary/20 rounded-full blur-3xl" />
            <div className="absolute -bottom-20 -right-20 w-40 h-40 bg-secondary/20 rounded-full blur-3xl" />

            <h1 className="cyber-heading mb-6 text-4xl md:text-5xl lg:text-6xl text-center md:text-left">
              <span className="gradient-text">Next-Gen Anime</span>
              <br />
              <span className="relative">
                Social Platform
                <div className="absolute -right-4 top-0 w-3 h-3 bg-primary rounded-full animate-ping" />
              </span>
            </h1>

            <p className="text-text-secondary text-lg md:text-xl mb-8 max-w-xl text-center md:text-left">
              Revolutionizing social connections through anime-themed profiles, secure chats, and dynamic content sharing.
            </p>

            <div className="flex flex-wrap justify-center md:justify-start gap-4 mb-10">
              <button
                onClick={() => setShowEarlyAccessForm(true)}
                className="btn-primary group"
              >
                <span className="flex items-center gap-2">
                  Early Access Beta
                  <ArrowRight className="group-hover:translate-x-1 transition-transform" />
                </span>
              </button>

              <button
                className="btn-secondary"
                onClick={() => setShowVideo(true)}
              >
                Watch Demo
              </button>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {stats.map((stat, index) => (
                <motion.div
                  key={stat.label}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="group relative"
                >
                  <div className="absolute inset-0 bg-gradient-to-r from-primary/20 to-secondary/20 rounded-lg blur-lg opacity-0 group-hover:opacity-100 transition-opacity" />
                  <div className="glass relative p-4 rounded-lg">
                    <stat.icon className="text-primary mb-2" size={24} />
                    <div className="text-xl md:text-2xl font-bold mb-1">{stat.value}</div>
                    <div className="text-sm text-text-secondary">{stat.label}</div>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>

          {/* Right content - Single Image */}
          {/* Right content - Single Image */}
<motion.div
  initial={{ opacity: 0, x: 50 }}
  animate={{ opacity: 1, x: 0 }}
  transition={{ duration: 0.8, delay: 0.2 }}
  className="relative mt-8 md:mt-0"
>
  <div className="absolute inset-0 bg-gradient-to-r from-primary/10 to-secondary/10 rounded-3xl blur-3xl" />
  <div className="relative glass p-4 md:p-6 rounded-3xl">
    {showVideo ? (
      <div className="aspect-video rounded-xl overflow-hidden">
        <video
          className="w-full h-full object-cover"
          controls
          controlsList="nodownload"
          autoPlay
        >
          <source src="/assets/videos/demo.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    ) : (
      <ImageSlider />
    )}
  </div>
</motion.div>
        </div>
      </div>

      {/* Early Access Form Modal */}
      <AnimatePresence>
        {showEarlyAccessForm && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/60 backdrop-blur-sm z-50 flex items-center justify-center p-4"
            onClick={(e) => {
              if (e.target === e.currentTarget) {
                setShowEarlyAccessForm(false);
                setSubmitStatus(null);
              }
            }}
          >
            <motion.div
              initial={{ scale: 0.95, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.95, opacity: 0 }}
              className="glass max-w-2xl w-full rounded-xl p-4 relative max-h-[90vh] overflow-y-auto"
              onClick={e => e.stopPropagation()}
            >
              <button
                onClick={() => {
                  setShowEarlyAccessForm(false);
                  setSubmitStatus(null);
                }}
                className="absolute top-4 right-4 text-text-secondary hover:text-white"
              >
                <X size={24} />
              </button>

              <div className="text-center mb-4">
                <h2 className="text-2xl font-bold text-glow mb-2">Get Early Access</h2>
                <p className="text-text-secondary">Join our exclusive beta testing program and shape the future of anime social networking.</p>
              </div>

              {submitStatus === 'success' ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="text-center py-4"
                >
                  <div className="w-16 h-16 bg-success/20 rounded-full flex items-center justify-center mx-auto mb-4">
                    <Check className="text-success" size={32} />
                  </div>
                  <h3 className="text-xl font-bold text-glow mb-2">Thank You!</h3>
                  <p className="text-text-secondary">Your early access request has been received. We'll contact you soon!</p>
                </motion.div>
              ) : (
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-text-secondary mb-2">
                      Name <span className="text-error">*</span>
                    </label>
                    <input
                      type="text"
                      required
                      className="input-cyber w-full"
                      value={formData.name}
                      onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                      placeholder="Enter your full name"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-text-secondary mb-2">
                      Email <span className="text-error">*</span>
                    </label>
                    <input
                      type="email"
                      required
                      className="input-cyber w-full"
                      value={formData.email}
                      onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                      placeholder="Enter your email"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-text-secondary mb-2">
                      Profession <span className="text-error">*</span>
                    </label>
                    <input
                      type="text"
                      required
                      className="input-cyber w-full"
                      value={formData.profession}
                      onChange={(e) => setFormData(prev => ({ ...prev, profession: e.target.value }))}
                      placeholder="What do you do?"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-text-secondary mb-2">Interests</label>
                    <div className="flex flex-wrap gap-2">
                      {interestOptions.map((interest) => (
                        <button
                          key={interest}
                          type="button"
                          className={`px-4 py-2 rounded-lg text-sm transition-all ${
                            formData.interests.includes(interest)
                              ? 'bg-primary text-white'
                              : 'bg-surface-light text-text-secondary hover:bg-surface-hover'
                          }`}
                          onClick={() => handleInterestToggle(interest)}
                        >
                          {interest}
                        </button>
                      ))}
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-text-secondary mb-2">
                      Why do you want early access? <span className="text-error">*</span>
                    </label>
                    <textarea
                      required
                      rows={2}
                      className="input-cyber w-full resize-none"
                      value={formData.reason}
                      onChange={(e) => setFormData(prev => ({ ...prev, reason: e.target.value }))}
                      placeholder="Tell us why you're interested in our platform..."
                    />
                  </div>

                  {renderFormError()}

                  <button
                    type="submit"
                    disabled={loading}
                    className={`btn-primary w-full ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                  >
                    {loading ? (
                      <div className="flex items-center justify-center gap-2">
                        <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                        <span>Submitting...</span>
                      </div>
                    ) : (
                      'Request Early Access'
                    )}
                  </button>
                </form>
              )}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
};

export default Hero;